import React from "react";

function ErrorMessage(props) {
  if (props.isHidden) {
    return null;
  }
  return <div>{props.errorMessage}</div>;
}

export default ErrorMessage;
