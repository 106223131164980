import httpClient from "./httpClient";

const BASE_API_URL = process.env.REACT_APP_API_URL;
const AUTH_API_URL = BASE_API_URL + "auth/";
const authService = {
  async add(user, isLoading) {
    isLoading(true);
    const response = await httpClient.post(AUTH_API_URL, user);
    sessionStorage.setItem("header", response.headers["authorization"]);
    isLoading(false);
    return response;
  },
};

export default authService;
