import React from "react";

const Buttons = ({
  careerLink,
  vegaSiteButtonText,
  optionalButtonText,
  handleNextLevel,
}) => {
  if (optionalButtonText !== "") {
    return (
      <div className="end-page__buttons">
        <a href={careerLink} className="btn btn--green-light end-page__btn">
          {vegaSiteButtonText}
        </a>{" "}
        <button onClick={handleNextLevel} className="btn btn--orange end-page__btn">
          {optionalButtonText}
        </button>
      </div>
    );
  }
  return (
    <div className="end-page__buttons">
      <a
        href={careerLink}
        className="btn btn--orange"
      >
        {vegaSiteButtonText}
      </a>
    </div>
  );
};
export default Buttons;
