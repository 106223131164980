const tokenService = {
  getTokenFromStorage() {
    return sessionStorage.getItem("header");
  },

  getAuthHeader() {
    return { Authorization: `${this.getTokenFromStorage()}` };
  },
};

export default tokenService;
