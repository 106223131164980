import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Answer from "../components/quiz/Answer";
import ScoreItem from "../components/quiz/ScoreItem";
import quizService from "../services/quizService";

function QuizPage() {
  const [overlay, setOverlay] = useState({ show: false, type: "" });
  const history = useHistory();
  const location = useLocation();
  if (!location.state) {
    history.push("/");
    return null;
  }
  const { data } = location.state;

  const answer = (id) => ({
    answer: id,
  });

  const ProgressBar = () => {
    const scoreItems = [];
    for (var i = 1; i <= data.total_questions; i++) {
      scoreItems.push(
        <ScoreItem
          key={i}
          statusList={data.status}
          current={data.ordinal_number}
          order={i}
        />
      );
    }
    return scoreItems;
  };

  const onClickHandler = async (id) => {
    const body = JSON.stringify(answer(id));
    const response = await quizService.addAnswer(body);

    const cover = {
      show: true,
      type: response.status[response.status.length - 1].is_correct
        ? "correct"
        : "wrong",
    };
    setOverlay(cover);

    setTimeout(function () {
      cover.show = false;
      setOverlay(cover);

      if (!data.has_next) {
        const state = {
          action: response.action,
          score: response.score,
          totalQuestions: response.total_questions,
          from: location.pathname,
        };
        if (response.action === "Winner") {
          history.push({
            pathname: "/winner",
            state: state,
          });
        } else {
          history.push({
            pathname: "/endQuiz",
            state: state,
          });
        }
      } else {
        history.push({
          pathname: "/quiz",
          state: { data: response },
        });
      }
    }, 600);
  };

  return (
    <div className="container">
      <section className="quiz">
        <ul className="quiz__steps">
          <li className="quiz__steps-item quiz__steps-item--highlight">
            {data.ordinal_number}
          </li>
          <li className="quiz__steps-item">{data.total_questions}</li>
        </ul>
        <div
          className={`quiz__overlay ${overlay.type} ${
            overlay.show ? "" : "hide"
          }`}
        >
          <div className="quiz__overlay-icon-wrap">
            <i className="quiz__overlay-icon"></i>
          </div>
        </div>
        <div className="quiz__question-wrap">
          <span className="quiz__text">{data.question.text}</span>
          <ul className="quiz__list">
            {data.question.answers.map((answer, i) => (
              <Answer
                id={answer.id}
                key={answer.id}
                text={answer.text}
                letter={String.fromCharCode(65 + i)}
                onClick={onClickHandler}
              />
            ))}
          </ul>
        </div>
        <div className="paging">
          <ul className="paging__list">
            <ProgressBar />
          </ul>
          <div className="paging__logo">
            <small className="paging__logo-text">vegait.rs</small>
            <i className="paging__logo-icon"></i>
          </div>
        </div>
      </section>
    </div>
  );
}

export default QuizPage;
