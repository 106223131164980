import ErrorMessage from "./ErrorMessage";

function Form(props) {
  const NameInput = (
    <input
      className="auth-quiz__input"
      type="text"
      value={props.user.name}
      name="name"
      placeholder="Name"
      onChange={(event) => props.onChange(event)}
      required
    />
  );

  const LastNameInput = (
    <input
      className="auth-quiz__input"
      type="text"
      value={props.user.LastName}
      name="LastName"
      placeholder="Last name"
      onChange={(event) => props.onChange(event)}
      required
    />
  );

  const EmailInput = (
    <input
      className="auth-quiz__input"
      type="email"
      value={props.user.email}
      name="email"
      placeholder="Email"
      onChange={(event) => props.onChange(event)}
      required
    />
  );

  const isErrorMessageHidden = (fieldValue) =>{
    return fieldValue.isValid || fieldValue.isValid === null
  }

  return (
    <form onSubmit={props.onSubmit} autoComplete="off">
      <div className="auth-quiz__form-row">
        {NameInput}
        <span className="auth-quiz__warning">
          <ErrorMessage
            isHidden={ isErrorMessageHidden(props.nameObject)}
            errorMessage={props.nameObject.message}
          />
        </span>
      </div>
      <div className="auth-quiz__form-row">
        {LastNameInput}
        <span className="auth-quiz__warning">
          <ErrorMessage
            isHidden={ isErrorMessageHidden(props.lastNameObject)}
            errorMessage={props.lastNameObject.message}
          />
        </span>
      </div>
      <div className="auth-quiz__form-row">
        {EmailInput}
        <span className="auth-quiz__warning">
          <ErrorMessage
            isHidden={ isErrorMessageHidden(props.emailObject)}
            errorMessage={props.emailObject.message}
          />
        </span>
      </div>
      <div className="auth-quiz__form-actions">
        <button
          className="btn btn--orange auth-quiz__btn"
          type="submit"
          disabled={props.disable}
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default Form;
