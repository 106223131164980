function Answer({id, onClick, letter, text}) {
    const handleClick = () => {
        onClick(id);
    }
    return(
        <li className="quiz__list-item">
            <button className="quiz__btn" onClick={handleClick}>
                <span className="quiz__btn-inner">
                    <b className="quiz__btn-letter">{letter} </b>
                    {text}
                </span>
            </button>
        </li>
    );
}
export default Answer;