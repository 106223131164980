import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../components/startQuiz/Button";
import Wheel from "../components/startQuiz/Wheel";
import quizInfoService from "../services/quizInfoService";
import quizService from "../services/quizService";

function StartQuizPage() {
  const [canChange, setCanChange] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [isAnotherSpin, SetIsAnotherSpin] = useState(false);
  const [level, setLevel] = useState({});
  const [forbidden, setForbidden] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const [buttonVisible, setButtonVisible] = useState(true);

  const [degree, SetDegree] = useState(0.0);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    var text = "";
    if (isAnotherSpin) {
      text = level.button_text !== "" ? level.button_text : "Spin again";
    } else {
      text = canChange
        ? level.button_text !== ""
          ? level.button_text
          : "Next"
        : quizInfo.textButton;
    }
    setButtonText(text);
  }, [buttonVisible]);

  const showNextButtonText = useCallback(() => {
    if (Object.keys(level).length == 0) {
      return;
    }
    if (!isAnotherSpin) {
      setIsPlayed(true);
    }
    setCanChange(true);
    setButtonVisible(true);
  }, [isAnotherSpin, level]);

  const MemoWheel = useMemo(
    () => (
      <Wheel degreeToSpin={degree} handleAnimationEnd={showNextButtonText} />
    ),
    [degree, showNextButtonText]
  );

  const getLevel = async () => {
    const result = await quizService.getLevel();
    if (result.status === 403) {
      handleAlreadyPlayed();
    }
    return result.data;
  };

  const getWheelDegree = (responseDegree) => {
    const delta = Math.floor(Math.random() * (10 - -10 + 1)) + -10;
    return parseFloat(responseDegree) + delta;
  };

  const handleAlreadyPlayed = () => {
    setForbidden(true);
    setTimeout(() => {
      history.push("/");
    }, 2000);
  };

  const handleFeelingLucky = () => {
    (async () => {
      const body = JSON.stringify({ section: "" });
      const response = await quizService.add(body);
      history.push({
        pathname: "/quiz",
        state: { data: response },
      });
    })();
  };

  const quizInfo = quizInfoService.fetchQuizDetails();

  const handleClick = async (event) => {
    if (isPlayed) {
      if (level.name === "T-SHIRT") {
        const redirect = {
          pathname: "/winner",
          state: {
            from: location.pathname,
          },
        };
        history.push(redirect);
      } else if (level.name === "LUCKY") {
        handleFeelingLucky();
      } else {
        const redirect = {
          pathname: "/pickQuiz",
          state: {
            level: level.name,
          },
        };
        history.push(redirect);
      }
      return;
    }
    setButtonVisible(false);

    const levelFromServer = await getLevel();
    const deltaDegree = getWheelDegree(levelFromServer.degree);
    SetDegree(deltaDegree);
    setLevel(levelFromServer);

    const anotherSpin = levelFromServer.name === "ANOTHER-SPIN" ? true : false;
    SetIsAnotherSpin(anotherSpin);
  };
  if (forbidden) {
    return <h1 className="spin__forbidden">You can't spin again!</h1>;
  }

  return (
    <div className="container">
      <header className="header header--no-border">
        <img
          src="./assets/images/logo1.svg"
          className="header__logo"
          alt="Vega IT logo"
        />
      </header>
      <div className="spin">
        <section className="spin__left">
          <section className="spin__splash-img-wrap">
            <img src={quizInfo.image} className="spin__splash-img" alt="logo" />
          </section>
          <section className="spin__text-wrap">
            <p className="spin__text">{quizInfo.description}</p>
          </section>
          <Button
            onClick={handleClick}
            text={buttonText}
            show={buttonVisible}
          />
        </section>
        <div className="spin__right">{MemoWheel}</div>
      </div>
    </div>
  );
}

export default StartQuizPage;
