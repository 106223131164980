import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import ShowEndPage from "../components/endPage/ShowEndPage";

function EndPage() {
  const location = useLocation();
  const history = useHistory();
  if (!location.state) {
    history.push("/");
    return null;
  }
  const { action, score, totalQuestions } = location.state;
  return (
    <ShowEndPage
      action={action}
      score={score}
      totalQuestions={totalQuestions}
    />
  );
}

export default EndPage;
