function ScoreItem({ order, current, statusList }) {
  const getStatusStyle = (index) => {
    try {
      const isCorrect = statusList[index - 1].is_correct;
      return isCorrect ? "paging__list-text paging__list-text--correct" : "paging__list-text paging__list-text--wrong";
    } catch {
      return "paging__list-text";
    }
  };

  const style = order === current ? "paging__list-item paging__list-item--current" : "paging__list-item";

  return (
    <li className={style}>
      <span className={getStatusStyle(order)}>{order}</span>
    </li>
  );
}
export default ScoreItem;
