import React, { useEffect, useState } from "react";
import quizService from "../services/quizService";
import { useHistory, useLocation } from "react-router-dom";

function WinnerPage() {
  const [status, setStatus] = useState("failed");
  const defaultFormState = {
    address: "",
    phone: "",
    shirt_size: "",
  };
  const [state, setState] = useState(defaultFormState);
  const [isForm, setIsForm] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state;

  useEffect(() => {
    if (status === 200) {
      if (from === "/startQuiz") {
        history.push(from);
      } else if (from === "/quiz") {
        history.push({
          pathname: "/endQuiz",
          state: location.state,
        });
      } else {
        history.push("/");
      }
    } else if (status === 403) {
      alert("You are not winner");
      history.push("/");
    }
  }, [status]);

  function handleChange(event) {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (state.address === "" || state.phone === "" || state.shirt_size === "") {
      return;
    }
    (async () => {
      const body = JSON.stringify(state);
      const response = await quizService.addWinner(body);
      setStatus(response.status);
    })();
  };

  const CongratsText = () => {
    return (
      <p className="winner__congrats-text">
        Congratulations!
        <br /> You've won a Vega IT T-shirt!
      </p>
    );
  };

  const handleNext = () => {
    setIsForm(true);
  };

  if (!isForm) {
    return (
      <div className="container">
        <div className="winner">
          <div className="winner__top">
            <img
              className="winner__congrats-image"
              src="assets/images/ico-confetti.svg"
              alt="confetti"
            />
            <CongratsText />
          </div>
          <p className="winner__text">
            Leave us your details and we'll get in touch with you!
          </p>
          <button
            type="submit"
            className="btn btn--orange btn--large"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="gift">
        <div className="gift__inner">
          <figure className="gift__top">
            <img
              className="gift__image"
              src="assets/images/ico-shirt.svg"
              alt="Orange outlined t-shirt logo"
            />
            <figcaption className="gift__caption">
              Choose your T-shirt size
            </figcaption>
          </figure>
          <div id="winner" className="gift__form">
            <form onSubmit={handleSubmit}>
              <ul className="gift__list">
                <li className="gift__list-item">
                  <label className="gift__label">Address:</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="address"
                    className="gift__input"
                  />
                </li>
                <li className="gift__list-item">
                  <label className="gift__label">Phone:</label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="phone"
                    className="gift__input"
                  />
                </li>
                <li className="gift__list-item">
                  <label className="gift__label">Shirt size:</label>
                  <div className="gift__radio-btns">
                    <span className="gift__radio">
                      <input
                        type="radio"
                        onChange={handleChange}
                        checked={state.shirt_size === "xs"}
                        value="xs"
                        name="shirt_size"
                        id="size-xs"
                        className="gift__radio-input"
                      />
                      <label htmlFor="size-xs" className="gift__radio-label">
                        XS:
                      </label>
                    </span>
                    <span className="gift__radio">
                      <input
                        type="radio"
                        onChange={handleChange}
                        checked={state.shirt_size === "s"}
                        value="s"
                        name="shirt_size"
                        id="size-s"
                        className="gift__radio-input"
                      />
                      <label htmlFor="size-s" className="gift__radio-label">
                        S:
                      </label>
                    </span>
                    <span className="gift__radio">
                      <input
                        onChange={handleChange}
                        checked={state.shirt_size === "m"}
                        type="radio"
                        name="shirt_size"
                        value="m"
                        id="size-m"
                        className="gift__radio-input"
                      />
                      <label htmlFor="size-m" className="gift__radio-label">
                        M:
                      </label>
                    </span>
                    <span className="gift__radio">
                      <input
                        type="radio"
                        onChange={handleChange}
                        checked={state.shirt_size === "l"}
                        value="l"
                        name="shirt_size"
                        id="size-l"
                        className="gift__radio-input"
                      />
                      <label htmlFor="size-l" className="gift__radio-label">
                        L:
                      </label>
                    </span>
                    <span className="gift__radio">
                      <input
                        onChange={handleChange}
                        checked={state.shirt_size === "xl"}
                        type="radio"
                        name="shirt_size"
                        value="xl"
                        id="size-xl"
                        className="gift__radio-input"
                      />
                      <label htmlFor="size-xl" className="gift__radio-label">
                        XL:
                      </label>
                    </span>
                  </div>
                </li>
              </ul>
              <div className="gift__form-actions">
                <button
                  type="submit"
                  className="btn btn--green-light gift__submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WinnerPage;
