import React from "react";

function Button({onClick, text, show}) {
  if(!show){
    return null;
  }
  return (
    <button className="btn btn--large btn--white spin__btn" onClick={onClick}>
      {text}
    </button>
  );
}

export default Button;
