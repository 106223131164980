import React from "react";
import styled, { keyframes } from "styled-components";

function Wheel({ degreeToSpin, handleAnimationEnd }) {
  const pulse = keyframes`
      from {
          transform: rotate(0deg);
          }
      to {
          transform: rotate(${degreeToSpin}deg);
      }`;

  const SpinImage = styled.div`
    img[data-state="true"] {
      animation: ${pulse};
      animation-duration: 6s;
      animation-fill-mode: forwards;
    }
  `;

  const ShowWheelImage = () => {
    return (
      <img
        className="spin__spinner-wheel"
        alt="spinner"
        data-state="true"
        src={sessionStorage.getItem("custom_wheel")}
      ></img>
    );
  };

  return (
    <figure className="spin__spinner-wrap">
      <img
        className="spin__spinner-stand"
        src="./assets/images/ico-stand.svg"
        alt=""
      />
      <img
        className="spin__spinner-dial"
        src="./assets/images/ico-dial.svg"
        alt=""
      />
      <SpinImage onAnimationEnd={handleAnimationEnd}>
        <ShowWheelImage />
      </SpinImage>
    </figure>
  );
}

export default Wheel;
