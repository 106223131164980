import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Form from "../components/login/Form";
import useDidMountEffect from "../components/login/useDidMountEffect";
import authService from "../services/authService";
import quizInfoService from "../services/quizInfoService";

const getDefaultPerson = () => ({
  name: "",
  LastName: "",
  email: "",
});

const userInfo = (user) => ({
  first_name: user.name,
  last_name: user.LastName,
  email: user.email,
  additional_information: "FTN",
});

function LoginPage(props) {
  const [user, setUser] = useState(getDefaultPerson());
  const [isInputNameValid, setIsInputNameValid] = useState(null);
  const [isInputLastNameValid, setIsInputLastNameValid] = useState(null);
  const [isInputEmailValid, setIsInputEmailValid] = useState(null);
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  const [nameValidationMessage, setNameValidationMessage] = useState("");
  const [LastNameValidationMessage, setLastNameValidationMessage] =
    useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [quizInfo, setQuizInfo] = useState({});

  const history = useHistory();

  useEffect(() => {
    (async function () {
      await quizInfoService.fetchConfigData();
      const info = quizInfoService.fetchQuizDetails();
      setQuizInfo(info);
      setIsLoading(false);
    })();
  }, []);

  const changeHandler = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  useDidMountEffect(() => {
    const timeOutId = setTimeout(() => {
      let isInputNameValid = ValidateInputName(user.name);
      setIsInputNameValid(isInputNameValid);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [user.name]);

  useDidMountEffect(() => {
    const timeOutId = setTimeout(() => {
      let isInputLastNameValid = ValidateInputLastName(user.LastName);
      setIsInputLastNameValid(isInputLastNameValid);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [user.LastName]);

  useDidMountEffect(() => {
    const timeOutId = setTimeout(() => {
      let isInputEmailValid = ValidateEmail(user.email);
      setIsInputEmailValid(isInputEmailValid);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [user.email]);

  useEffect(() => {
    setIsDisabled(isDisabledMethod());
  }, [isInputNameValid, isInputLastNameValid, isInputEmailValid]);

  const ValidateInputName = (input) => {
    if (input !== "" || input == null) {
      return true;
    }
    setNameValidationMessage("Please specify non-empty name");
    return false;
  };

  const ValidateInputLastName = (input) => {
    if (input !== "") {
      return true;
    }
    setLastNameValidationMessage("Please specify non-empty LastName");
    return false;
  };

  const ValidateEmail = (input) => {
    if (input !== "") {
      let regexp = /\S+@\S+\.\S+/;

      if (!regexp.test(String(input).toLowerCase())) {
        setEmailValidationMessage("Email pattern is not valid");
        return false;
      }
      return true;
    }
    setEmailValidationMessage("Please specify non-empty email");
    return false;
  };

  const isDisabledMethod = () => {
    if (isInputNameValid && isInputLastNameValid && isInputEmailValid) {
      return false;
    }
    return true;
  };

  const getAlertMessage = (responseData) => {
    if (responseData.includes("email")) {
      return "Email already exists! Please specify another one!";
    } else if (responseData.includes("first_name")) {
      return "Please check first name!";
    } else if (responseData.includes("last_name")) {
      return "Please check last name!";
    } else {
      return "Something went wrong! Please try again later";
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const body = JSON.stringify(userInfo(user));

    const response = await authService.add(body, setIsLoading);
    if (response.status >= 400) {
      alert(getAlertMessage(response.data));
    } else {
      history.push("/startQuiz");
    }
  };

  if (isLoading) {
    return <div className="spin-loader"></div>;
  }

  return (
    <div className="container">
      <header className="header">
        <img
          className="header__logo"
          src="assets/images/logo1.svg"
          alt="Vega it logo"
        />
        <span className="header__text">Vega IT Quiz</span>
      </header>
      <section className="auth-quiz">
        <figure className="auth-quiz__img-wrap">
          <img
            className="auth-quiz__img"
            src={quizInfo.image}
            alt="Answer and win vega it t-shirt"
          />
        </figure>
        <div className="auth-quiz__form">
          <Form
            user={user}
            onSubmit={submitHandler}
            onChange={changeHandler}
            disable={isDisabled}
            nameObject={{
              isValid: isInputNameValid,
              message: nameValidationMessage,
            }}
            lastNameObject={{
              isValid: isInputLastNameValid,
              message: LastNameValidationMessage,
            }}
            emailObject={{
              isValid: isInputEmailValid,
              message: emailValidationMessage,
            }}
          />
        </div>
      </section>
    </div>
  );
}

export default LoginPage;
