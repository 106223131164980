import React from "react";

const ScoreParagraph = ({ action, score, totalQuestions }) => {
  if (action === "None") {
    return null;
  }
  return (
    <div className="end-page__result">
      {score}<span className="end-page__result-total">/{totalQuestions} </span>
    </div>
  );
};
export default ScoreParagraph;
