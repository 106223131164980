import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import StartQuizPage from "./pages/StartQuizPage";
import PickQuizPage from "./pages/PickQuizPage";
import QuizPage from "./pages/QuizPage";
import EndPage from "./pages/EndPage";
import WinnerPage from "./pages/WinnerPage";

function App() {
  window.onpopstate = function (event) {
    window.history.pushState(null, "", "/");
  };

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LoginPage />
        </Route>
        <Route exact path="/startQuiz">
          <StartQuizPage />
        </Route>
        <Route exact path="/pickQuiz">
          <PickQuizPage />
        </Route>
        <Route exact path="/quiz">
          <QuizPage />
        </Route>
        <Route exact path="/endQuiz">
          <EndPage />
        </Route>
        <Route exact path="/winner">
          <WinnerPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
