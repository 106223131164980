import React from "react";
import PickQuizButton from "./PickQuizButton";

function PickQuizButtonList({items, onClick}) {
  if (items.length === 0) {
    return <h2> Found no quizzes. </h2>;
  }

  const CreateImage = (buttonItem) => {
    return (
      <img
        className="pick__list-img"
        alt={buttonItem.name}
        src={buttonItem.image}
      />
    );
  };

  const MapItems = () =>{
    return items.map((buttonItem) => (
      <PickQuizButton
        key={buttonItem.id}
        name={buttonItem.name}
        img={CreateImage(buttonItem)}
        onClick={onClick}
      />
    ))
  }

  return (
    <ul className="pick__list">
      <MapItems />
    </ul>
  );
}

export default PickQuizButtonList;
