import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import db from "../../data/db.json";
import quizService from "../../services/quizService";
import Buttons from "./Buttons";
import ScoreParagraph from "./ScoreParagraph";

function ShowEndPage({ action, score, totalQuestions }) {
  const history = useHistory();
  const dbValue =
    action === "Winner"
      ? db.Winner
      : action === "NextLevel"
      ? db.NextLevel
      : db.None;
  const [pageData, setPageData] = useState(dbValue);
  const [isLoading, setIsLoading] = useState(true);

  const pageType = () => {
    switch (action) {
      case "Winner":
        return "WinnerPage";
      case "NextLevel":
        return "NextLevelPage";
      default:
        return "ParticipantPage";
    }
  };

  const mapData = (endPageData) => {
    const data = pageData;
    data.imageSource = endPageData.image;
    data.fistParagraph = endPageData.first_paragraph;
    data.secondParagraph = endPageData.second_paragraph;
    data.VegaSiteButtonText = endPageData.vega_site_button_text;
    data.OptionalButtonText = endPageData.optional_button_text;
    data.VegaSiteButtonUrl = endPageData.vega_site_button_url;
    return data;
  };

  useEffect(() => {
    (async function () {
      const response = await quizService.getEndPage(pageType());
      const endPageData = response.data;
      const data = mapData(endPageData);
      setPageData(data);
      setIsLoading(false);
    })();
  }, []);

  const handleNextLevel = () => {
    (async () => {
      const body = JSON.stringify({ section: "" });
      const response = await quizService.add(body);
      history.push({
        pathname: "/quiz",
        state: { data: response },
      });
    })();
  };

  const stripScripts = (html) => {
    var div = document.createElement("div");
    div.innerHTML = html;
    var scripts = div.getElementsByTagName("script");
    var i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    return div.innerHTML;
  };

  if (isLoading) {
    return <div className="spin-loader"></div>;
  }

  return (
    <div className="container">
      <header className="header">
        <img
          src="./assets/images/logo1.svg"
          className="header__logo"
          alt="Vega IT logo"
        />
      </header>
      <section className="end-page">
        <div className="end-page__content">
          <div className="end-page__img-wrap">
            <img
              className={pageData.imageClass}
              src={pageData.imageSource}
              alt={pageData.imageAlt}
            />
          </div>
          <ScoreParagraph
            action={action}
            score={score}
            totalQuestions={totalQuestions}
          />
          <div className="end-page__text-wrap">
            <h3
              className="end-page__title"
              dangerouslySetInnerHTML={{
                __html: stripScripts(pageData.fistParagraph),
              }}
            ></h3>
            <p
              className="end-page__text"
              dangerouslySetInnerHTML={{
                __html: stripScripts(pageData.secondParagraph),
              }}
            ></p>
          </div>
        </div>
        <Buttons
          careerLink={pageData.VegaSiteButtonUrl}
          vegaSiteButtonText={pageData.VegaSiteButtonText}
          optionalButtonText={pageData.OptionalButtonText}
          handleNextLevel={handleNextLevel}
        />
      </section>
    </div>
  );
}

export default ShowEndPage;
