import axios from "axios";

const getHeaders = (token) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (token !== null) {
    headers["Authorization"] = token;
  }
  return headers;
};

const httpClient = {
  async get(url, token = null) {
    const headers = getHeaders(token);
    const response = await axios.get(url, { headers }).catch((error) => {
      if (error.response.status === 406) {
        alert("Quiz is not active!");
        window.location.href = error.response.data;
      }
      if (error.response.status !== 403) {
        console.log(error);
      }
      return error.response;
    });
    return response;
  },

  async post(url, body = {}, token = null) {
    const headers = getHeaders(token);
    const response = await axios.post(url, body, { headers }).catch((error) => {
      if (error.response) {
        if (error.response.status >= 400 && error.response.status < 500) {
          return error.response;
        }
      } else {
        console.log(error);
      }
    });
    return response;
  },
};

export default httpClient;
