import httpClient from "./httpClient";
import tokenService from "./tokenService";

const BASE_API_URL = process.env.REACT_APP_API_URL;
const CONFIG_API_URL = BASE_API_URL + "config";
const LEVEL_API_URL = BASE_API_URL + "level";
const SECTIONS_API_URL = BASE_API_URL + "section";
const START_QUIZ_API_URL = BASE_API_URL + "quiz/";
const WINNER_API_URL = BASE_API_URL + "auth/win";
const ANSWER_API_URL = BASE_API_URL + "quiz/question";
const END_PAGE_API_URL = BASE_API_URL + "endpage";
const header = () => {
  return tokenService.getTokenFromStorage();
};

const quizService = {
  async getConfig() {
    const response = await httpClient.get(CONFIG_API_URL);
    return response.data;
  },

  async getLevel() {
    return await httpClient.get(LEVEL_API_URL, header());
  },

  async getAllSections() {
    const response = await httpClient.get(SECTIONS_API_URL, header());
    return response.data;
  },

  async getEndPage(page) {
    return await httpClient.get(END_PAGE_API_URL + "?page=" + page, header());
  },

  async add(section) {
    const response = await httpClient.post(
      START_QUIZ_API_URL,
      section,
      header()
    );
    return response.data;
  },

  async addWinner(winner) {
    return await httpClient.post(WINNER_API_URL, winner, header());
  },

  async addAnswer(answer) {
    const response = await httpClient.post(ANSWER_API_URL, answer, header());
    return response.data;
  },
};

export default quizService;
