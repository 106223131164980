import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import quizService from "../services/quizService";
import PickQuizButtonList from "../components/pickQuiz/PickQuizButtonList";

function PickQuizPage() {
  const [quizzes, setQuizzes] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const level = location?.state?.level;

  useEffect(() => {
    if (!location.state) {
      history.push("/");
      return null;
    }
    setTimeout(() => {
      return getSections();
    }, 500);
  }, []);

  const getSections = async () => {
    const result = await quizService.getAllSections();
    setQuizzes(result);
    setIsLoaded(true);
  };

  const clickHandler = async (name) => {
    const sectionInfo = (name) => ({
      section: name,
    });
    const body = JSON.stringify(sectionInfo(name));
    const response = await quizService.add(body);

    history.push({
      pathname: "/quiz",
      state: { data: response },
    });
  };

  return (
    <div className="container">
      <header className="header">
        <img
          src="assets/images/logo1.svg"
          className="header__logo"
          alt="Vega it logo"
        />
        <span className="header__text">Vega IT Quiz</span>
      </header>
      <div className="pick">
        <h3 className="pick__title">The wheel has spoken :)</h3>
        <div className="pick__list-wrap">
          <h4 className="pick__list-title">{level}</h4>

          {isLoaded && (
            <PickQuizButtonList
              items={quizzes}
              onClick={clickHandler}
            ></PickQuizButtonList>
          )}
        </div>
      </div>
    </div>
  );
}

export default PickQuizPage;
