import React from "react";

function PickQuizButton({name, img, onClick}) {
  const handleClick = () => {
    onClick(name);
  }
  return (
    <li className="pick__list-item">
      <button
        type="button"
        className="pick__list-btn"
        onClick={handleClick}
      >
        {img}
      </button>
    </li>
  );
}

export default PickQuizButton;
