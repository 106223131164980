import quizService from "../services/quizService";

const quizInfoService = {
  async fetchConfigData() {
    const configFromServer = await quizService.getConfig();
    const keys = Object.keys(configFromServer);
    keys.forEach((key, index) => {
      sessionStorage.setItem(`${key}`, configFromServer[key]);
    });
  },

  fetchQuizDetails() {
    const description = sessionStorage.getItem("description");
    const image = sessionStorage.getItem("image");
    const textButton = sessionStorage.getItem("button_text");

    return {
      description,
      image,
      textButton,
    };
  },
};

export default quizInfoService;
